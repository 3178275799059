<template>
  <div class="orderSucc">
    <div class="order-succ">
      <span class="order-succ-txt">订单支付成功!!!</span><br />
      <span class="order-succ-orderlist" @click="toOrder">去订单页面</span>
      <span class="order-succ-goodlist" @click="togood">去商品主页</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    toOrder() {
      this.$router.push("orderList");
    },
    togood() {
      this.$router.push("/");
    },
  },
};
</script>

<style>
.order-succ {
  width: 400px;
  height: 200px;
  background: aqua;
  margin: auto;
}
.order-succ-txt {
  line-height: 200px;
  font-weight: 600;
  font-size: 18px;
  margin-left: 34%;
}
.order-succ-orderlist {
  font-size: 14px;
}
.order-succ-goodlist {
  font-size: 14px;
  padding-left: 64%;
}
</style>